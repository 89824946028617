import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import initialImage from "./assets/images/00568_00_00013_00.png";
import "./App.css";

const general1 = {
  width: "100%",
  height: "100%",
};

const itemInnerBox = {
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "repeat(4, 25%)",
  listStyle: "none",
  margin: "0",
  padding: "0",
};
const item = {
  width: "100%",
  height: "100%",
  display: "block",
  backgroundColor: "red",
  border: "0.5px solid white",
  listStyle: "none",
};

const container = {
  display: "flex",
  width: "100%",
  maxWidth: "1114px",
  height: "528.219px",
  justifyContent: "center",
  alignItems: "center",
};

const fontStyle = {
  fontSHeight: "1.625",
  marginBottom: "12px",
  fontWeight: "400",
  color: "rgba(255,255,255,0.6)",
};
const emptyLine = {
  width: "8.33333%",
  padding: "0 14 0 14",
};

const modelImageStyle = {
  backgroundColor: "transparent",
  width: "auto",
  height: "80%",
};
const data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

function App() {
  const [selectCloth, setSelectCloth] = useState(0);
  const [selectModel, setSelectModel] = useState(0);
  const [modelImages, setModelImages] = useState([]);
  const [clothImages, setClothImages] = useState([]);
  const [mainImages, setMainImages] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimation2, setShowAnimation2] = useState(false);
  const [mainModelCloth, setMainModelCloth] = useState(initialImage);
  let modelData = data;
  let clothData = data;
  let importAll = undefined;
  let images = [];

  useEffect(() => {
    setShowAnimation(true);
    setShowAnimation2(true);

    importAll = (r) => r.keys().map(r);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    images = importAll(
      require.context("./assets/models", false, /\.(png|jpe?g|svg)$/)
    );
    setModelImages([...images]);
    setSelectModel(0);

    images = importAll(
      require.context("./assets/cloths", false, /\.(png|jpe?g|svg)$/)
    );
    setClothImages([...images]);
    setSelectCloth(0);

    images = importAll(
      require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
    );
    setMainImages([...images]);
    setSelectCloth(initialImage);
  }, []);

  useEffect(() => {
    findMainModelCloth();
  }, [selectCloth, selectModel]);

  function findStringIndex(arr, searchString) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].includes(searchString)) {
        return i;
      }
    }
    return -1;
  }

  const extractStringName = (str) => {
    const regex = /\/([^/]+)\.jpg$/;
    const match = str?.match(regex);
    const fileName = match ? match[1] : "";

    const index = fileName?.indexOf(".");
    const extractedString = index !== -1 ? fileName?.substring(0, index) : "";
    return extractedString;
  };

  const findMainModelCloth = () => {
    const keyword =
      extractStringName(modelImages[selectModel]) +
      "_" +
      extractStringName(clothImages[selectCloth]);
    const index = findStringIndex(mainImages, keyword);
    setMainModelCloth(mainImages[index]);
    return keyword;
  };

  const handleOnClickCloth = (id) => {
    setSelectCloth(id);
    // findMainModelCloth();
  };
  const handleOnClickModel = (id) => {
    setSelectModel(id);
    // findMainModelCloth();
  };

  return (
    <div className={`App + ${showAnimation ? "fade-in" : ""}`}>
      <div
        className={`reactive-container ${showAnimation2 ? "fade-in2" : ""}`}
        style={container}
      >
        <div className="contain-outer-box">
          <ul style={itemInnerBox}>
            {modelImages?.map((e, idx) => (
              <li
                key={idx + e}
                className={`item ${selectModel === idx ? "chomo" : ""}`}
                onClick={() => handleOnClickModel(idx)}
              >
                <img style={modelImageStyle} src={e}></img>
              </li>
            ))}
          </ul>
        </div>

        <div style={emptyLine} />

        <div className="image-outer-box">
          <div style={{ width: "100%" }}>
            <img style={{ width: "100%" }} src={mainModelCloth} />
            <p style={fontStyle}>Synthesized Photo</p>
          </div>
        </div>

        <div style={emptyLine} />

        <div className="contain-outer-box">
          <ul style={itemInnerBox}>
            {clothImages.map((e, idx) => (
              <li
                key={idx + e}
                className={`item ${selectCloth === idx ? "chocl" : ""}`}
                onClick={() => handleOnClickCloth(idx)}
              >
                <img style={modelImageStyle} src={e}></img>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
